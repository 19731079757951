import { Chat, Message } from "./types"

export function createUserMessage(message: string, chat: Chat | null): Message {
  const userMessage: Message = {
    messageId: Date.now().toString(),
    chatId: chat?.chatId || "",
    userId: "",
    role: "user",
    content: message,
    createdAt: new Date().toISOString(),
  }

  return userMessage
}

export function createAssistantMessage(message: string, chat: Chat | null): Message {
  const assistantMessage: Message = {
    messageId: Date.now().toString(),
    chatId: chat?.chatId || "",
    userId: "",
    role: "assistant",
    content: message,
    createdAt: new Date().toISOString(),
  }

  return assistantMessage
}
