<template>
  <div>
    <section ref="dropzone" class="mb-4">
      <form-dialog
        :name="$t('images.title')"
        :id="editable.id"
        v-model:visible="dialogVisible"
        :saving="saving"
        @submit="updateImage"
      >
        <form-row v-for="locale in translatedLocales" :key="locale">
          <template v-slot:label>
            <label>
              {{ $t("images.name") }}
              <flag :locale="locale" />
            </label>
          </template>
          <div class="w-full relative">
            <nice-input size="small" v-model="editable['title_' + locale]" />
            <char-counter
              :count="editable['title_' + locale] ? editable['title_' + locale].length : 0"
              :total="30"
              :title="$t('images.counter')"
            />
          </div>
        </form-row>
        <form-row :title="$t('images.portals')">
          <nice-radio-group
            v-model="privateState"
            class="w-100"
            :options="[
              { id: 'yes', name: $t('images.y') },
              { id: 'no', name: $t('images.n') },
              { id: 'particular', name: $t('images.specific') },
            ]"
          />
        </form-row>
        <form-row v-if="privateState == 'particular'" :title="$t('images.specificPortals')">
          <db-select collection="uniqConnections" multiple v-model="editable.connection_ids" />
        </form-row>
        <form-row :title="$t('images.markFloorplan')">
          <nice-switch v-model="editable.is_floorplan" />
        </form-row>
        <form-row :title="$t('images.notForExposee')">
          <nice-switch v-model="editable.is_not_for_exposee" color="danger" />
        </form-row>
        <form-row v-if="$db.shopData.watermarkOnPortals" :title="$t('images.disableWatermarking')">
          <nice-switch v-model="editable.disable_watermarking" color="danger" />
        </form-row>
        <form-row v-if="projectId" :title="$t('images.units')" :class="{ disabled: editable.is_not_for_exposee }">
          <nice-switch v-model="editable.bequest" />
        </form-row>
        <form-row v-if="false" :title="$t('images.location')">
          <nice-switch v-model="editable.location" />
        </form-row>
        <form-row :title="$t('images.tags')" :hint="$t('images.tagsHint')">
          <nice-select
            v-model="editable.tags"
            default-first-option
            multiple
            filterable
            clearable
            :label-fn="tag => tag"
            :options="tags"
            :placeholder="$t('images.tagsPh')"
          >
            <el-option v-for="item in tags" :label="item" :key="item" :value="item"></el-option>
          </nice-select>
        </form-row>
      </form-dialog>

      <div class="d-flex justify-content-between align-items-center mb-3">
        <div>
          <h5 class="fs-16">
            {{ $customFilters.pluralize(items.length, $t("images.title"), $t("images.titlePlural")) }}
            <span class="text-gray-400">{{ " (davon " + items.filter(i => i.is_private).length + " privat)" }}</span>
          </h5>
          <p class="text-muted fs-12" v-if="parentProjectImagesCount">
            +
            {{
              $t("images.imgCount", {
                amount: $customFilters.pluralize(
                  parentProjectImagesCount,
                  $t("images.title"),
                  $t("images.titlePlural")
                ),
              })
            }}
          </p>
        </div>
        <aside class="flex items-center">
          <dropdown v-if="listview && canEdit" :class="{ disabled: selected.length <= 0 }">
            <template v-slot:title>
              {{ $t("images.actions", { selected: selected.length }) }}
              <fa-icon name="angle-down" class="ml-1" />
            </template>
            <dropdown-section>
              <dropdown-item :title="$t('images.toPortals')" @click="bulkEdit(image => (image.is_private = false))" />
              <dropdown-item :title="$t('images.fromPortals')" @click="bulkEdit(image => (image.is_private = true))" />
            </dropdown-section>
            <dropdown-section>
              <dropdown-item
                :title="$t('images.showExposee')"
                @click="bulkEdit(image => (image.is_not_for_exposee = false))"
              />
              <dropdown-item
                :title="$t('images.hideExposee')"
                @click="bulkEdit(image => (image.is_not_for_exposee = true))"
              />
            </dropdown-section>
            <dropdown-section>
              <dropdown-item
                :title="$t('images.markFloorplan')"
                @click="bulkEdit(image => (image.is_floorplan = true))"
              />
              <dropdown-item
                :title="$t('images.unmarkFloorplan')"
                @click="bulkEdit(image => (image.is_floorplan = false))"
              />
            </dropdown-section>
            <dropdown-section v-if="$db.shopData.watermarkOnPortals">
              <dropdown-item
                :title="$t('images.enableWatermark')"
                @click="bulkEdit(image => (image.disable_watermarking = false))"
              />
              <dropdown-item
                :title="$t('images.disableWatermark')"
                @click="bulkEdit(image => (image.disable_watermarking = true))"
              />
            </dropdown-section>
            <dropdown-section>
              <dropdown-item class="text-danger" :title="$t('images.delete')" @click="bulkRemove(selected)" />
            </dropdown-section>
          </dropdown>
          <upload-button
            v-if="canEdit"
            :title="$t('images.upload')"
            :url="baseURL"
            :onSend="onSend"
            accepted-files="image/*"
            @success="items.push($event)"
            :parentDropzone="true"
            class="mr-1"
          />
          <dropdown>
            <template v-slot:title>
              <fa-icon name="ellipsis-h" />
            </template>
            <dropdown-section>
              <dropdown-item
                :title="listview ? $t('images.toggleView1') : $t('images.toggleView2')"
                @click="toggleListView"
              />
              <dropdown-item
                :title="$t('images.downloadAll')"
                :link="`/api/v1/images/download?property_id=${propertyId || ''}&project_id=${projectId || ''}`"
                new-tab
                :disabled="items.length <= 0"
              />
              <dropdown-item
                v-if="property"
                :title="$t('siteplan.open')"
                :disabled="!property?.lat || !property.lng"
                @click="initMap"
                :tooltip="!property?.lat || !property.lng ? $t('siteplan.tooltip') : ''"
              />
            </dropdown-section>
          </dropdown>
        </aside>
      </div>
      <div class="psTable-wrapper">
        <ps-data-table
          id="images-data-table"
          v-if="listview && items.length"
          :columns="imagesListViewColumns"
          :data="items"
          :selected="canEdit ? selectedIds : undefined"
          sortable-single
          @update:selected="payload => (selectedIds = payload)"
          @update:row-moved="handleSort"
        >
          <template v-slot:title="{ resource }">
            <input
              type="text"
              :placeholder="$t('images.desc')"
              class="image-title"
              :disabled="!canEdit || resource.placeholder"
              v-model="resource[`title_${defaultLocale}`]"
              @keydown.enter="$event.target.blur()"
              @mouseup="$event.target.select()"
              @change="updateImage(resource)"
            />
            <fa-icon v-if="resource.ai_original_image_id" name="magic" class="ml-2" />
          </template>
          <template v-slot:chars="{ resource }">
            <char-counter
              :count="resource[`title_${defaultLocale}`] ? resource[`title_${defaultLocale}`].length : 0"
              :total="30"
              :absolute="false"
            />
          </template>

          <template v-slot:photo="{ resource }">
            <spinner v-if="resource.placeholder" />
            <img
              v-else
              :src="resource.photo_url_thumb"
              class="w-full"
              :title="items.indexOf(resource) === 0 ? $t('images.itemTitle') : ''"
              @click="lightGalleryIndex = items.indexOf(resource)"
            />
          </template>
          <template v-slot:is_floorplan="{ resource }">
            <nice-checkbox
              v-model="resource.is_floorplan"
              :disabled="!canEdit || resource.placeholder"
              @change="updateImage(resource)"
            ></nice-checkbox>
          </template>
          <template v-slot:is_not_for_exposee="{ resource }">
            <nice-checkbox
              :disabled="!canEdit || resource.placeholder"
              :model-value="!resource.is_not_for_exposee"
              @update:model-value="val => (resource.is_not_for_exposee = !val)"
              @change="updateImage(resource)"
            ></nice-checkbox>
          </template>
          <template v-slot:is_private="{ resource }">
            <nice-checkbox
              :model-value="!resource.is_private"
              :disabled="!canEdit || resource.placeholder"
              @update:model-value="val => (resource.is_private = !val)"
              @change="updateImage(resource)"
            />
          </template>
          <template v-slot:bequest="{ resource }">
            <nice-checkbox
              v-model="resource.bequest"
              :disabled="resource.placeholder"
              @change="updateImage(resource)"
            />
          </template>
          <template :key="tag" v-for="tag in tags" v-slot:[tag]="{ resource }">
            <nice-checkbox
              :disabled="resource.placeholder"
              :model-value="resource.tags && resource.tags.includes(tag)"
              @update:model-value="toggleTag($event, resource, tag)"
              @change="updateImage(resource)"
            />
          </template>
          <template v-slot:last_cell="{ resource }">
            <div class="last-cell flex">
              <dropdown :disabled="resource.placeholder" trigger="hover" placement="left" offset="1" width="270">
                <template #reference>
                  <hover-action
                    v-if="$db.featureActive('immovision') && canEdit"
                    icon="magic"
                    class="mr-2"
                    :disabled="resource.placeholder"
                  />
                </template>
                <dropdown-section>
                  <dropdown-item @click="enhanceImage(resource)" class="px-3 w-100 flex justify-between">
                    Bildoptimierung
                    <span class="text-gray-400 text-sm">(ca. 1min)</span>
                  </dropdown-item>
                  <dropdown-item @click="immovisionStageModalVisible = resource.id" :disabled="resource.ai_operation == 'stage'" class="px-3 w-100 flex justify-between">
                    Virtuelles Homestaging
                    <span class="text-gray-400 text-sm">(ca. 2-3min)</span>
                  </dropdown-item>
                  <dropdown-item @click="immovisionRemodelModalVisible = resource.id" :disabled="resource.ai_operation == 'remodel'" class="px-3 w-100 flex justify-between">
                    Virtuelle Raumrenovierung
                    <span class="text-gray-400 text-sm">(ca. 2-5min)</span>
                  </dropdown-item>
                </dropdown-section>
              </dropdown>
              <hover-action
                v-if="canEdit"
                @click="editImage(resource)"
                :disabled="resource.placeholder"
                icon="pencil"
                class="mr-2"
              />
              <hover-action
                v-if="canEdit"
                @click="removeImage(resource)"
                :disabled="resource.placeholder"
                icon="trash-alt"
                class="text-danger"
              />
            </div>
          </template>
        </ps-data-table>
      </div>
      <div v-if="!listview">
        <div v-if="canEdit">
          <draggable
            class="images-list"
            group="images"
            :model-value="items"
            @update:model-value="items = $event"
            item-key="id"
            @end="updateImagePositions"
          >
            <template v-if="canEdit" #item="{ element: image, index }">
              <image-item
                :image="image"
                :class="{ 'is-title-image': index == 0 }"
                :selected="isSelected(image)"
                :can-edit="true"
                @fullscreen="lightGalleryIndex = index"
                @edit="editImage(image)"
                @delete="removeImage(image)"
                @toggle-select="toggleSelect(image)"
                @watermark="watermarkImage(image)"
                :locale="defaultLocale"
                @immovision-handler="immovisionHandler"
              />
            </template>
          </draggable>
        </div>
        <div v-else class="images-list">
          <div v-for="(image, index) in items" :key="index">
            <image-item
              :image="image"
              :class="{ 'is-title-image': index == 0 }"
              :selected="isSelected(image)"
              :can-edit="false"
              @fullscreen="lightGalleryIndex = index"
              @toggle-select="toggleSelect(image)"
              :locale="defaultLocale"
            />
          </div>
        </div>
      </div>

      <vue-easy-lightbox
        class="nice-lightbox"
        :visible="lightGalleryIndex !== null"
        :imgs="lightGalleryImages"
        :index="lightGalleryIndex"
        @hide="lightGalleryIndex = null"
      />
      <form-dialog
        :title="immovisionStageModalVisible ? 'Virtuelles Homestaging' : 'Virtuelle Raumrenovierung'"
        submit-button-title="Bild generieren"
        :visible="immovisionPopupVisible"
        width="400px"
        append-to-body
        footer-type="bigButton"
        :disabled="!(immovision.stage.roomType && immovision.stage.style) && !(immovision.remodel.roomType && immovision.remodel.style)"
        @close="immovisionPopupClose"
        @submit="submitImmovision"
      >
        <form-section v-if="immovisionStageModalVisible">
          <span>Die Bildgenerierung durch die AI kann einige Minuten in Anspruch nehmen. (ca. 2-3min)</span>
          <form-row direction="column" title="Raum wählen" class="mt-4">
            <nice-select fixed-position v-model="immovision.stage.roomType" :options="immovisionOptions.staging.roomTypes" />
          </form-row>
          <form-row direction="column" title="Designstil">
            <nice-select fixed-position v-model="immovision.stage.style" :options="immovisionOptions.staging.styles" />
          </form-row>
        </form-section>
        <form-section v-else>
          <span>Die Bildgenerierung durch die AI kann einige Minuten in Anspruch nehmen. (ca. 2-5min)</span>
          <form-row direction="column" title="Raum wählen" class="mt-4">
            <nice-select fixed-position v-model="immovision.remodel.roomType" :options="immovisionOptions.remodeling.roomTypes" />
          </form-row>
          <form-row direction="column" title="Designstil">
            <nice-select fixed-position v-model="immovision.remodel.style" :options="immovisionOptions.remodeling.styles" />
          </form-row>
        </form-section>
      </form-dialog>
      <form-dialog
        :title="$t('siteplan.modal')"
        v-model:visible="sitePlan.modalVisible"
        width="540px"
        footer-type="bigButton"
        :submit-func="exportMap"
        :submit-button-title="$t('siteplan.submit')"
        append-to-body
        :disabled="!sitePlan.title"
        @complete="mapExportCompleted"
      >
        <header class="mb-2">
          <aside style="width: 300px">
            <label>{{ $t("siteplan.title.label") }}</label>
            <nice-input size="small" v-model="sitePlan.title" :placeholder="$t('siteplan.title.placeholder')" />
          </aside>
        </header>
        <div ref="map" class="rounded border" :style="{ width: '500px', height: '500px' }"></div>
      </form-dialog>
    </section>
    <mc-grundriss-widget
      integration-id="integration-mcgrundriss-ps"
      :images="items"
      :locale="defaultLocale"
      :entity="property"
      v-if="propertyId && canEdit && $db.broker.canSeeMarketing"
    />
  </div>
</template>

<script>
import ImageItem from "./ImageItem"
import { immovisionOptions } from "./immovisionOptions.js"
import UploadButton from "./UploadButton"
import saveStateMixin from "../../mixins/save-state-mixin"
import L from "leaflet"
import "leaflet/dist/leaflet.css"
import leafletImage from "leaflet-image"
import imgUrl from "@/assets/images/marker.png"
import Flag from "@/components/Flag.vue"
import { coerceBooleanAttribute } from "@/utils/booleanAttributeCoercion"
import McGrundrissWidget from "@/views/admin/components/integrations/McGrundrissWidget.vue"

const COLUMNS = [
  { name: "photo", title: "", width: 50, visible: true },
  { name: "title", title: "title", width: 500, visible: true },
  { name: "chars", title: "chars", width: 70, visible: true },
  { name: "is_private", title: "portals", width: 70, visible: true },
  {
    name: "is_not_for_exposee",
    title: "exposee",
    width: 70,
    visible: true,
  },
  { name: "bequest", title: "bequest", width: 70, visible: true },
  { name: "is_floorplan", title: "floorplan", width: 75, visible: true },
]

export default {
  props: [
    "propertyId",
    "projectId",
    "initialItems",
    "translations",
    "parentProjectImagesCount",
    "tags",
    "brokerIds",
    "readonly",
    "property",
    "project",
  ],
  components: {
    ImageItem,
    UploadButton,
    Flag,
    McGrundrissWidget,
  },
  mixins: [saveStateMixin],
  data(props) {
    return {
      immovisionOptions,
      items: props.initialItems,
      dialogVisible: false,
      saving: false,
      editable: {},
      lightGalleryIndex: null,
      listview: false,
      privateState: "yes",
      selectedIds: [],
      immovisionStageModalVisible: null,
      immovisionRemodelModalVisible: null,
      sitePlan: {
        title: "",
        modalVisible: false,
        map: null,
        tileLayer: null,
        exporting: false,
      },
      immovision: {
        stage: {
          roomType: null,
          style: null,
        },
        remodel: {
          roomType: null,
          style: null,
        }
      },
    }
  },
  watch: {
    privateState(newValue) {
      if (newValue !== "particular") this.editable.connection_ids = []
      if (newValue == "no") {
        this.editable.is_private = true
      } else {
        this.editable.is_private = false
      }
    },
  },
  methods: {
    toggleListView() {
      this.listview = !this.listview
    },
    toggleSelect(img) {
      if (this.isSelected(img)) {
        this.selectedIds = this.selectedIds.filter(id => id != img.id)
      } else {
        this.selectedIds.push(img.id)
      }
    },
    isSelected(img) {
      return this.selectedIds.includes(img.id)
    },
    toggleSelectedAll() {
      this.selectedIds.length === this.items.length
        ? (this.selectedIds = [])
        : (this.selectedIds = this.items.map(i => i.id))
    },
    onSend(formData) {
      if (this.propertyId) {
        formData.append("property_id", this.propertyId)
      }
      if (this.projectId) {
        formData.append("project_id", this.projectId)
      }
    },
    async bulkEdit(func) {
      const images = JSON.parse(JSON.stringify(this.selected))
      images.forEach(image => func(image))
      return Promise.all(images.map(image => this.updateImage(image, true))).then(data => {
        images.forEach(image => {
          this.items = this.items.map(img => (img.id === image.id ? image : img))
        })
        App.flashy(this.$t("images.editSuccess", { images: images.length }))
      })
    },
    editImage(image) {
      this.editable = JSON.parse(JSON.stringify(image))
      this.dialogVisible = true
      if (this.editable.is_private == true) {
        this.privateState = "no"
      } else {
        this.editable.connection_ids.length ? (this.privateState = "particular") : (this.privateState = "yes")
      }
    },
    watermarkImage(image) {
      window.open(`/api/v1/images/${image.id}/watermark`, "_blank")
    },
    updateImage(img, preventUpdate) {
      this.saving = true
      const image = img || this.editable
      this.$axios
        .put(`${this.baseURL}/${image.id}`, { image })
        .then(() => {
          // App.flashy("Das Bild wurde erfolgreich geändert.")
          this.saving = false
          if (!preventUpdate) this.items = this.items.map(img => (img.id === image.id ? image : img))
          this.dialogVisible = false
          this.$emit("update:images", this.items)
        })
        .catch(this.$axios.handleError)
    },

    bulkRemove(images) {
      const message =
        images.length > 1 ? this.$t("images.massDelWarn", { images: images.length }) : this.$t("images.delWarn")

      if (confirm(message)) {
        Promise.all(
          images.map(image => {
            this.$axios.delete(`${this.baseURL}/${image.id}`)
            this.items = this.items.filter(img => img.id !== image.id)
          })
        ).then(res => {
          App.flashy(
            this.$t("images.delSuccess", {
              numerus: images.length > 1 ? this.$t("images.titlePlural") : this.$t("images.title"),
            })
          )
          this.$emit("update:images", this.items)
        })
      }
    },

    removeImage(img) {
      this.$warn(
        {
          title: this.$t("images.warn.title"),
          desc: this.$t("images.warn.desc"),
          confirmButtonText: this.$t("images.warn.btn"),
          redButton: true,
        },
        () => {
          const image = img || this.selected[0]
          this.$axios.delete(`${this.baseURL}/${image.id}`).then(() => {
            this.items = this.items.filter(img => img.id !== image.id)
            App.flashy(this.$t("images.warn.success"))
            this.$emit("update:images", this.items)
          })
        }
      )
    },
    handleSort(newOrder) {
      this.items = newOrder
      this.updateImagePositions()
    },
    updateImagePositions() {
      this.$axios.put(this.sortURL, {
        item: this.items.map(o => o.id),
      })
    },
    toggleTag(val, resource, tag) {
      if (!resource.tags) resource.tags = [tag]
      val ? (resource.tags = resource.tags.concat(tag)) : (resource.tags = resource.tags.filter(t => t !== tag))
    },

    initMap() {
      this.sitePlan.modalVisible = true
      setTimeout(() => {
        this.sitePlan.map = L.map(this.$refs.map).setView([this.property.lat, this.property.lng], 13)

        // Tile layer
        this.sitePlan.tileLayer = L.tileLayer("https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png", {
          attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors',
        })

        var LeafIcon = L.Icon.extend({
          options: {
            iconSize: [32, 32],
            iconAnchor: [16, 32],
          },
        })
        var greenIcon = new LeafIcon({
          iconUrl: imgUrl,
        })

        this.sitePlan.tileLayer.addTo(this.sitePlan.map)

        var markerLocation = L.latLng(this.property.lat, this.property.lng)
        var marker = L.marker(markerLocation, { icon: greenIcon }).addTo(this.sitePlan.map)
      }, 100)
    },
    exportMap() {
      return new Promise((resolve, reject) => {
        leafletImage(this.sitePlan.map, (err, canvas) => {
          const base64 = canvas.toDataURL()

          this.$api
            .mutation(
              "createSitePlan",
              {
                propertyId: this.propertyId,
                projectId: this.projectId,
                image: base64,
                title: this.sitePlan.title,
              },
              `image {
              id titleDe titleHu titleEn titleEs titleFr titleRu titleIt
              photoUrl tags bequest
              photoUrlThumb: photoUrl(version: "thumb")
              position, tags, disableWatermarking
              isPrivate, isFloorplan, isNotForExposee, connectionIds
            }`
            )
            .then(resolve)
            .catch(reject)
        })
      })
    },
    mapExportCompleted({ image }) {
      this.items.push(this.$util.snakeCaseify(image))
      this.sitePlan.modalVisible = false
      App.flashy(this.$t("siteplan.success"))
    },
    immovisionPopupClose() {
      this.immovisionStageModalVisible = null
      this.immovisionRemodelModalVisible = null
      this.immovision.stage.roomType = null
      this.immovision.stage.style = null
      this.immovision.remodel.roomType = null
      this.immovision.remodel.style = null
    },
    immovisionHandler(items) {
      const { method, image } = items
      if (method == "enhance") this.enhanceImage(image)
      if (method == "stage") this.immovisionStageModalVisible = image.id
      if (method == "remodel") this.immovisionRemodelModalVisible = image.id
    },
    createTemporaryPlaceholder(image) {
      const placeholderImage = { ...image, id: `p${image.id}`, placeholder: true }
      this.items.push(this.$util.snakeCaseify(placeholderImage))
      this.items = this.items.sort((a, b) => a.position - b.position)
      this.immovisionPopupClose()
    },
    enhanceImage(image) {
      const selectedImage = this.items.find(i => i.id == image.id)
      this.$api
        .mutation("enhanceImage", { imageId: image.id })
        .then(() => {
          this.createTemporaryPlaceholder(selectedImage)
          App.flashy("Das Bild wird verbessert. In ein paar Sekunden sind wir schlauer...")
        })
        .catch(this.$axios.handleError)
    },
    stageImage(imageId) {
      const selectedImage = this.items.find(i => i.id == imageId)
      this.$api
        .mutation("stageImage", { imageId: imageId, roomType: this.immovision.stage.roomType, style: this.immovision.stage.style })
        .then(() => {
          this.createTemporaryPlaceholder(selectedImage)
          App.flashy("Das Bild wird verbessert. In ein paar Sekunden sind wir schlauer...")
        })
        .catch(this.$axios.handleError)
    },
    remodelImage(imageId) {
      const selectedImage = this.items.find(i => i.id == imageId)
      this.$api
        .mutation("remodelImage", { imageId: imageId, roomType: this.immovision.remodel.roomType, style: this.immovision.remodel.style })
        .then(() => {
          this.createTemporaryPlaceholder(selectedImage)
          App.flashy("Das Bild wird verbessert. In ein paar Sekunden sind wir schlauer...")
        })
        .catch(this.$axios.handleError)
    },
    submitImmovision() {
      if (this.immovisionStageModalVisible) this.stageImage(this.immovisionStageModalVisible)
      if (this.immovisionRemodelModalVisible) this.remodelImage(this.immovisionRemodelModalVisible)
    },
    subscribe() {
      const resourceId = this.projectId || this.propertyId
      const resourceType = this.projectId ? "project" : "property"
      this.$pusher.off(`image_enhanced:${resourceType}:${resourceId}`)
      this.$pusher.on(`image_enhanced:${resourceType}:${resourceId}`, ({ image_id }) => {
        this.$graphql(
          `query image {
          image(id: $id) {
            id titleDe titleEn titleEs titleFr titleRu titleIt titleHu
            photoUrl tags bequest
            photoUrlThumb: photoUrl(version: "thumb")
            position, tags, disableWatermarking
            isPrivate, isFloorplan, isNotForExposee, connectionIds
            aiOriginalImageId aiOperation
          }
        }`,
          { id: image_id }
        )
          .then(({ image }) => {
            this.items = this.items.filter(i => i.id != `p${image.aiOriginalImageId}`)
            console.log(image, this.items)
            this.items.push(this.$util.snakeCaseify(image))
            this.items = this.items.sort((a, b) => a.position - b.position)
            App.flashy("Das verbesserte Bild wurde erfolgreich erstellt & hinzugefügt.")
          })
          .catch(this.$axios.handleError)
      })
    },
  },
  computed: {
    immovisionPopupVisible() {
      return !!this.immovisionStageModalVisible || !!this.immovisionRemodelModalVisible
    },
    isDisabled() {
      return coerceBooleanAttribute(!this.canEdit)
    },
    canEdit() {
      if (this.readonly) return false

      if (this.projectId) {
        return (
          this.$db.broker.canEditUnits ||
          (this.$db.broker.canEditSharedUnits &&
            !!this.project &&
            (this.project.writeBrokerIds?.includes(this.$db.broker.id) ||
              this.project.writeDepartmentIds?.some(id => this.$db.broker.departmentIds.includes(id)))) ||
          (!!this.brokerIds && this.brokerIds.includes(this.$db.broker.id))
        )
      }

      return (
        this.$db.broker.canEditUnits ||
        (this.$db.broker.canEditSharedUnits &&
          !!this.property &&
          (this.property.brokerIds?.includes(this.$db.broker.id) ||
            this.property.departmentIds?.some(id => this.$db.broker.departmentIds.includes(id)))) ||
        (!!this.brokerIds && this.brokerIds.includes(this.$db.broker.id))
      )
    },
    saveStateConfig() {
      return {
        cacheKey: `mediaImageListView`,
        saveProperties: ["listview"],
      }
    },
    selected() {
      return this.items.filter(img => this.selectedIds.includes(img.id))
    },
    baseURL() {
      return "/portfolio/images"
    },
    sortURL() {
      return `/api/v1/images/sort?property_id=${this.propertyId || ""}&project_id=${this.projectId || ""}`
    },
    lightGalleryImages() {
      return this.items.map(img => ({
        title: img[`title_${this.defaultLocale}`],
        url: img.photo_url,
        src: img.photo_url,
      }))
    },
    columns() {
      const columnTags = this.tags.map(t => ({ name: t, title: t, width: 70, visible: true, tag: true }))
      const temp = COLUMNS.concat(columnTags)
      return temp.filter(col => col.name != "bequest" || this.projectId)
    },
    imagesListViewColumns() {
      return this.columns.map(col => ({
        ...col,
        key: col.name,
        title: col.title && !col.tag ? this.$t(`images.columns.${col.title}`) : col.title,
      }))
    },
    translatedLocales() {
      const locales = [this.$db.shopData.language]
        .concat(this.$db.shopData.supportedPropertyLocales || [])
        .filter(Boolean)
      return this.translations?.filter(locale => locales.includes(locale)) || []
    },
    defaultLocale() {
      return this.translatedLocales?.[0] ?? this.$db.shopData.language
    },
  },
  mounted() {
    this.subscribe()
  },
}
</script>

<style lang="postcss">
.images-list {
  @apply flex flex-row flex-wrap;
}

#images-data-table .psTable-wrap {
  margin-left: 0 !important;
}

#images-data-table .psTable-rows {
  flex: 1 0;
}

.image-title {
  width: 100%;
  height: 36px;
  padding-top: 8px;
  padding-bottom: 8px;
  border: none;
  overflow: hidden;
  white-space: nowrap;
  background-color: transparent;
}

.psTable-wrapper {
  margin: 0 -6px;
}

#images-data-table .psTable-row .last-cell,
#images-data-table .psTable-row .handle {
  opacity: 0;
}

#images-data-table .psTable-row:hover .last-cell,
#images-data-table .psTable-row:hover .handle {
  opacity: 1;
}

#images-data-table .psTable-row:last-child {
  border: 0px;
}

.nice-lightbox.vel-modal {
  background: rgba(0, 0, 0, 0.8);
}
.nice-lightbox {
  .vel-img-title {
    font-size: 1.2 rem;
    color: #fff;
    opacity: 0.9;
    background: rgba(0, 0, 0, 0.6);
    padding: 8px;
  }
  .vel-btns-wrapper {
    .btn__close {
      font-size: 50px;
      background: rgb(0, 0, 0);
      top: 35px;
      opacity: 0.8;
    }
    .btn__prev {
      background: rgb(0, 0, 0);
      padding: 10px 4px;
    }
    .btn__next {
      background: rgb(0, 0, 0);
      padding: 10px 4px;
    }
  }
}
</style>
