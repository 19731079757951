<template>
  <div
    :class="[
      'flex flex-col mb-2 rounded-lg p-2 relative',
      align === 'left' ? 'ml-auto mr-4 bg-sky-100' : 'ml-4 bg-slate-100',
    ]"
  >
    <div :class="['flex items-center text-xs font-medium mb-2', { 'self-end': align === 'right' }]">
      <div v-if="align === 'left'" class="mr-2">
        <avatar :broker="$db.broker" force-photo />
      </div>
      {{ formattedDate }}
      <div v-if="align === 'right'" class="ml-2">
        <fa-icon name="magic" class="text-lg" />
      </div>
    </div>
    <div class="flex">
      <div class="flex-1" v-html="renderedContent"></div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed, ref, onMounted, watch } from "vue"
import { Message } from "../types"
import { marked } from "marked"
import DOMPurify from "dompurify"

const props = defineProps<{ message: Message }>()
const align = computed(() => (props.message.role === "user" ? "left" : "right"))
const formattedDate = computed(() => moment(props.message.createdAt).fromNow())

const renderedContent = ref("")

const renderMarkdown = async () => {
  try {
    const rawHtml = await marked(props.message.content)
    renderedContent.value = DOMPurify.sanitize(rawHtml)
  } catch (error) {
    console.error("Error rendering markdown:", error)
    renderedContent.value = props.message.content // Fallback to raw content
  }
}

onMounted(renderMarkdown)

watch(() => props.message.content, renderMarkdown)
</script>

<style scoped>
:deep(h1) {
  font-size: 1.4rem;
  font-weight: 700;
  padding-top: 0.5rem;
  padding-bottom: 0.25rem;
}
:deep(h2) {
  font-size: 1.3rem;
  font-weight: 700;
  padding-top: 0.5rem;
  padding-bottom: 0.25rem;
}
:deep(h3) {
  font-size: 1.2rem;
  font-weight: 700;
  padding-top: 0.5rem;
  padding-bottom: 0.25rem;
}
:deep(h4) {
  font-size: 1.1rem;
  font-weight: 700;
  padding-top: 0.5rem;
  padding-bottom: 0.25rem;
}
:deep(h5) {
  font-size: 1rem;
  font-weight: 700;
  padding-top: 0.5rem;
  padding-bottom: 0.25rem;
}
:deep(h6) {
  font-size: 1rem;
  font-weight: 700;
  padding-top: 0.5rem;
  padding-bottom: 0.25rem;
}
</style>
