export const immovisionOptions = {
  staging: {
    roomTypes: [
      { id: "living", name: "Wohnzimmer" },
      { id: "bed", name: "Schlafzimmer" },
      { id: "dining", name: "Esszimmer" },
      { id: "home_office", name: "Homeoffice" },
      { id: "outdoor", name: "Garten" },
      { id: "kids_room", name: "Kinderzimmer" },
    ],
    styles: [
      { id: "modern", name: "Modern" },
      { id: "scandinavian", name: "Skandinavisch" },
      { id: "industrial", name: "Industriell" },
      { id: "midcentury", name: "Mid-Century" },
      { id: "luxury", name: "Luxus" },
      { id: "farmhouse", name: "Landhaus" },
      { id: "coastal", name: "Strandhaus" },
      { id: "standard", name: "Standard" },
    ],
  },
  remodeling: {
    roomTypes: [
      { id: "Living Room", name: "Wohnzimmer" },
      { id: "Bedroom", name: "Schlafzimmer" },
      { id: "Kitchen Living Combo", name: "Küche-Wohnzimmer-Kombination" },
      { id: "Bathroom", name: "Badezimmer" },
      { id: "Attic", name: "Dachboden" },
      { id: "Kitchen", name: "Küche" },
      { id: "Kids Room", name: "Kinderzimmer" },
      { id: "Dining Room", name: "Esszimmer" },
      { id: "Study Room", name: "Arbeitszimmer" },
      { id: "Home Office", name: "Homeoffice" },
      { id: "Gaming Room", name: "Spielzimmer" },
      { id: "Meeting Room", name: "Besprechungsraum" },
      { id: "Workshop", name: "Werkstatt" },
      { id: "Gym", name: "Fitnessraum" },
      { id: "Coffee Shop", name: "Café" },
      { id: "Clothing Store", name: "Bekleidungsgeschäft" },
      { id: "Walk In Closet", name: "Begehbarer Kleiderschrank" },
      { id: "Toilet", name: "Toilette" },
      { id: "Restaurant", name: "Restaurant" },
      { id: "Office", name: "Büro" },
      { id: "Coworking Space", name: "Coworking-Bereich" },
      { id: "Hotel Lobby", name: "Hotel-Lobby" },
      { id: "Hotel Room", name: "Hotelzimmer" },
      { id: "Hotel Bathroom", name: "Hotelbadezimmer" },
      { id: "Exhibition Space", name: "Ausstellungsraum" },
      { id: "Onsen", name: "Onsen (japanisches Badehaus)" },
      { id: "Mudroom", name: "Hauswirtschaftsraum" },
      { id: "Drop Zone", name: "Drop Zone" },
      { id: "House Exterior", name: "Außenbereich des Hauses" },
      { id: "Outdoor Pool Area", name: "Außenpool" },
      { id: "Outdoor Patio", name: "Terrasse" },
      { id: "Outdoor Garden", name: "Garten" },
    ],
    styles: [
      { id: "Modern", name: "Modern" },
      { id: "Minimalist", name: "Minimalistisch" },
      { id: "Scandinavian", name: "Skandinavisch" },
      { id: "Contemporary", name: "Zeitgenössisch" },
      { id: "Airbnb", name: "Airbnb" },
      { id: "Midcentury Modern", name: "Mid-Century Modern" },
      { id: "Industrial", name: "Industriell" },
      { id: "Gaming Room", name: "Spielzimmer" },
      { id: "Coastal", name: "Strandhaus" },
      { id: "Farmhouse", name: "Landhaus" },
      { id: "Rustic", name: "Rustikal" },
      { id: "Art Deco", name: "Art Deco" },
      { id: "Futuristic", name: "Futuristisch" },
      { id: "Zen", name: "Zen" },
      { id: "Retro Futuristic", name: "Retro-futuristisch" },
      { id: "Neoclassic", name: "Neoklassisch" },
      { id: "Vaporwave", name: "Vaporwave" },
      { id: "Cottagecore", name: "Cottagecore" },
      { id: "Art Nouveau", name: "Jugendstil" },
      { id: "Bohemian", name: "Böhmisch" },
      { id: "Japanese Design", name: "Japanisches Design" },
      { id: "Biophilic", name: "Biophil" },
      { id: "Vintage", name: "Vintage" },
      { id: "Sketch", name: "Skizzenstil" },
      { id: "Tropical", name: "Tropisch" },
      { id: "French Country", name: "Französischer Landhausstil" },
      { id: "Maximalist", name: "Maximalistisch" },
      { id: "Tribal ", name: "Tribal" },
      { id: "Cyberpunk", name: "Cyberpunk" },
      { id: "Halloween", name: "Halloween" },
      { id: "Easter ", name: "Ostern" },
      { id: "Ski Chalet", name: "Ski-Chalet" },
      { id: "Medieval", name: "Mittelalterlich" },
      { id: "Hot Pink", name: "Hot Pink" },
      { id: "Baroque", name: "Barock" },
      { id: "Christmas", name: "Weihnachten" },
    ],
  },
  // staging: { ---------- waiting for translations
  //   roomTypes: [
  //     { id: "living" },
  //     { id: "bed" },
  //     { id: "dining" },
  //     { id: "home_office" },
  //     { id: "outdoor" },
  //     { id: "kids_room" },
  //   ],
  //   styles: [
  //     { id: "modern" },
  //     { id: "scandinavian" },
  //     { id: "industrial" },
  //     { id: "midcentury" },
  //     { id: "luxury" },
  //     { id: "farmhouse" },
  //     { id: "coastal" },
  //     { id: "standard" },
  //   ],
  // },
  // remodeling: {
  //   roomTypes: [
  //     { id: "Living Room", name: "livingRoom" },
  //     { id: "Bedroom" },
  //     { id: "Kitchen Living Combo", name: "kitchenLivingCombo" },
  //     { id: "Bathroom" },
  //     { id: "Attic" },
  //     { id: "Kitchen" },
  //     { id: "Kids Room", name: "kidsRoom" },
  //     { id: "Dining Room", name: "diningRoom" },
  //     { id: "Study Room", name: "studyRoom" },
  //     { id: "Home Office", name: "homeOffice" },
  //     { id: "Gaming Room", name: "gamingRoom" },
  //     { id: "Meeting Room", name: "meetingRoom" },
  //     { id: "Workshop" },
  //     { id: "Gym" },
  //     { id: "Coffee Shop", name: "coffeShop" },
  //     { id: "Clothing Store", name: "clothingStore" },
  //     { id: "Walk In Closet", name: "walkInCloset" },
  //     { id: "Toilet" },
  //     { id: "Restaurant" },
  //     { id: "Office" },
  //     { id: "Coworking Space", name: "coworkingSpace" },
  //     { id: "Hotel Lobby", name: "hotelLobby" },
  //     { id: "Hotel Room", name: "hotelRoom" },
  //     { id: "Hotel Bathroom", name: "hotelBathroom" },
  //     { id: "Exhibition Space", name: "exhibitionSpace" },
  //     { id: "Onsen" },
  //     { id: "Mudroom" },
  //     { id: "Drop Zone" },
  //     { id: "House Exterior" },
  //     { id: "Outdoor Pool Area" },
  //     { id: "Outdoor Patio" },
  //     { id: "Outdoor Garden" },
  //   ],
  //   styles: [
  //     { id: "Modern" },
  //     { id: "Minimalist" },
  //     { id: "Scandinavian" },
  //     { id: "Contemporary" },
  //     { id: "Airbnb" },
  //     { id: "Midcentury Modern" },
  //     { id: "Industrial" },
  //     { id: "Gaming Room" },
  //     { id: "Coastal" },
  //     { id: "Farmhouse" },
  //     { id: "Rustic" },
  //     { id: "Art Deco" },
  //     { id: "Futuristic" },
  //     { id: "Zen" },
  //     { id: "Retro Futuristic" },
  //     { id: "Neoclassic" },
  //     { id: "Vaporwave" },
  //     { id: "Cottagecore" },
  //     { id: "Art Nouveau" },
  //     { id: "Bohemian" },
  //     { id: "Japanese Design" },
  //     { id: "Biophilic" },
  //     { id: "Vintage" },
  //     { id: "Sketch" },
  //     { id: "Tropical" },
  //     { id: "French Country" },
  //     { id: "Maximalist" },
  //     { id: "Tribal " },
  //     { id: "Cyberpunk" },
  //     { id: "Halloween" },
  //     { id: "Easter " },
  //     { id: "Ski Chalet" },
  //     { id: "Medieval" },
  //     { id: "Hot Pink" },
  //     { id: "Baroque" },
  //     { id: "Christmas" },
  //   ],
  // },
}
