<template>
  <div
    v-if="isOpen"
    className="absolute bottom-16 right-2 w-[400px] rounded-lg border border-slate-200 bg-white shadow-xl z-[10001]"
  >
    <div className="border-b border-slate-200 p-4">
      <h3 className="text-lg font-semibold">
        <fa-icon name="magic" class="text-lg" />
        {{ $t("aiChat.title") }}
      </h3>
      <p className="py-2">
        {{ $t("aiChat.description") }}
        <span>
          <a
            target="_blank"
            href="https://propstack.zendesk.com/hc/de/articles/20369754879005-Einsatz-und-Risiken-von-KI-Textgenerierung"
          >
            {{ $t("aiChat.descriptionLink") }}
          </a>
        </span>
      </p>

      <div className="h-80 overflow-y-auto py-2" ref="containerRef">
        <chat-message v-for="message in messages" :key="message.messageId" :message="message" />
        <div v-if="messages.length === 0" class="italic text-neutral-light">
          <p>
            {{ $t("aiChat.startConversation") }}
          </p>
        </div>
        <div v-if="isLoading" className="flex w-full items-center justify-center">
          <ChatLoadingIndicator />
        </div>
      </div>
      <div className="border-t border-slate-200 pt-4">
        <div className="flex">
          <input
            ref="inputRef"
            type="text"
            :value="inputText"
            @input="handleInputChange"
            @keydown.enter="handleSendMessage"
            className="flex-grow rounded-l-lg border border-slate-300 px-4 py-2
          focus:outline-none"
            :placeholder="$t('aiChat.typeMessage')"
          />
          <button
            @click="handleSendMessage"
            className="rounded-r-lg bg-primary px-4 py-2 text-white hover:bg-primary"
            :disabled="isLoading"
          >
            {{ $t("aiChat.submit") }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { useChatAIContext } from "./use-chat-ai"
import ChatLoadingIndicator from "./components/LoadingIndicator.vue"
import ChatMessage from "./components/ChatMessage"

import { ref, watch } from "vue"

const inputText = ref("")
const inputRef = ref()
const containerRef = ref()

const { isOpen, isLoading, messages, sendMessage } = useChatAIContext()

watch(isLoading, newIsLoading => {
  if (!newIsLoading) {
    inputRef.value.focus()
  }
})

watch(
  () => messages.value?.[messages.value.length - 1]?.content,
  () => {
    setTimeout(() => {
      if (!containerRef.value) return
      containerRef.value.scrollTop = containerRef.value.scrollHeight
    }, 0)
  }
)

watch(isOpen, () => {
  setTimeout(() => {
    if (!containerRef.value) return
    containerRef.value.scrollTop = containerRef.value.scrollHeight
  }, 0)
})

const handleSendMessage = () => {
  if (isLoading.value || inputText.value.trim() === "") return
  sendMessage(inputText.value)
  inputText.value = ""
}

const handleInputChange = (event: Event) => {
  inputText.value = (event.target as HTMLInputElement).value
}
</script>

<style></style>
