import { provide, inject, ref, Ref } from "vue"
import { Chat, Message } from "./types"
import { createUserMessage } from "./utils"
import api from "../../plugins/api"
import useCore from "@/plugins/use-core"

const CHAT_AI_CONTEXT = Symbol()

export interface IChatAI {
  isOpen: Ref<boolean>
  isLoading: Ref<boolean>
  messages: Ref<Message[]>
  sendMessage: (message: string) => Promise<void>
  open: () => void
  close: () => void
}

export function useChatAIProvider() {
  const { graphql } = useCore()
  const isOpen = ref(false)
  const isLoading = ref(false)
  const chat = ref<Chat | null>(null)
  const messages = ref<Message[]>([])

  const loadMessages = async () => {
    try {
      const reponse = await graphql(`
        {
          broker {
            primaryAiChat {
              aiMessages {
                id
                role
                content
                createdAt
              }
            }
          }
        }
      `)

      const aiMessages = reponse.broker?.primaryAiChat?.aiMessages

      if (aiMessages) {
        messages.value = aiMessages.map((msg: any) => ({
          messageId: msg.id,
          chatId: chat.value?.chatId || "",
          userId: "",
          role: msg.role,
          content: msg.content,
          createdAt: msg.createdAt,
        }))
      }
    } catch (error) {
      console.error("Error loading messages:", error)
    }
  }

  const sendMessage = async (message: string) => {
    isLoading.value = true

    const text = message.trim()

    const newMessage = createUserMessage(text, chat.value)
    messages.value.push(newMessage)

    try {
      const response = await api.mutation(
        "messageAiAssistant",
        {
          content: message,
        },
        "messages {id, role, content, createdAt}"
      )

      console.log("response", response)

      // Update messages with the response from the backend
      if (response.messages) {
        messages.value = response.messages.map((msg: any) => ({
          messageId: msg.id,
          chatId: chat.value?.chatId || "",
          userId: "",
          role: msg.role,
          content: msg.content,
          createdAt: msg.createdAt,
        }))
      }
    } catch (error) {
      console.error("Error sending message:", error)
      // Handle error (e.g., show an error message to the user)
    } finally {
      isLoading.value = false
    }
  }

  const open = () => {
    isOpen.value = true
    loadMessages()
  }

  const close = () => {
    isOpen.value = false
  }

  provide<IChatAI>(CHAT_AI_CONTEXT, {
    isOpen,
    open,
    isLoading,
    messages,
    sendMessage,
    close,
  })
}

export function useChatAIContext(): IChatAI {
  const context = inject<IChatAI>(CHAT_AI_CONTEXT)

  if (!context) {
    throw new Error("useChatAIContext must be used with useChatAIProvider")
  }

  return context
}
