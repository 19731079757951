<template>
  <div
    v-if="canEdit && !image.placeholder"
    :data-id="image.id"
    class="property-image dropdown-hover"
    :class="{
      'is-floorplan': image.is_floorplan,
      'is-private': image.is_private,
      'is-selected': selected,
      'rainbow-box': image.ai_original_image_id
    }"
  >
    <div class="image-bg" :style="{ 'background-image': `url('${image.photo_url_thumb}')` }"></div>
    <input
      type="text"
      placeholder="Bildbeschreibung"
      class="image-title"
      v-model="image[`title_${locale}`]"
      @keydown.enter="$event.target.blur()"
      @focus="$event.target.select()"
      @change="updateTitle"
    />
    <dropdown v-if="$db.featureActive('immovision')" trigger="hover" offset="1" width="270">
      <template #reference>
        <fa-icon name="magic" class="hover-visibility absolute right-2 bottom-3 cursor-pointer color-effect" />
      </template>
      <dropdown-section>
        <dropdown-item @click="immovisionEmit('enhance')" class="px-3 w-100 flex justify-between">Bildoptimierung<span class="text-gray-400 text-sm">(ca. 1min)</span></dropdown-item>
        <dropdown-item @click="immovisionEmit('stage')" :disabled="image.ai_operation == 'stage'" class="px-3 w-100 flex justify-between">Virtuelles Homestaging<span class="text-gray-400 text-sm">(ca. 2-3min)</span></dropdown-item>
        <dropdown-item @click="immovisionEmit('remodel')" :disabled="image.ai_operation == 'remodel'" class="px-3 w-100 flex justify-between">Virtuelle Raumrenovierung<span class="text-gray-400 text-sm">(ca. 2-5min)</span></dropdown-item>
      </dropdown-section>
    </dropdown>
    <a tabindex="-1" href="#" @click.prevent="$emit('edit')" class="action-trigger edit-trigger">
      <fa-icon name="pencil" />
    </a>
    <a tabindex="-1" href="#" @click.prevent="$emit('delete')" class="action-trigger delete-trigger">
      <fa-icon name="trash-alt" />
    </a>
    <a tabindex="-1" href="#" @click.prevent="$emit('fullscreen')" class="action-trigger fullscreen-trigger">
      <fa-icon name="expand-alt" />
    </a>
    <a
      tabindex="-1"
      href="#"
      @click.prevent="$emit('watermark')"
      class="action-trigger watermark-trigger"
      v-if="$db.shopData.watermarkOnPortals && !image.disable_watermarking"
      title="Bild mit Wasserzeichen herunterladen"
    >
      <fa-icon name="tint" />
    </a>

    <span class="image-indicators">
      <span class="image-floorplan-indicator" title="Grundriss">
        <fa-icon name="expand-wide" />
      </span>
      <span class="image-private-indicator" title="Privat">
        <fa-icon name="lock" />
      </span>
    </span>
  </div>
  <div
    v-else
    class="property-image"
    :class="{
      'is-floorplan': image.is_floorplan,
      'is-private': image.is_private,
    }"
  >
    <spinner v-if="image.placeholder" class="image-bg" />
    <div v-else class="image-bg" :style="{ 'background-image': `url('${image.photo_url_thumb}')` }"></div>
    <p class="image-title">{{ image[`title_${locale}`] }}</p>
    <a
      href="#"
      v-if="!image.placeholder"
      @click.prevent="$emit('fullscreen')"
      class="action-trigger fullscreen-trigger"
    >
      <fa-icon name="expand-alt" />
    </a>
  </div>
</template>

<script>
export default {
  props: ["image", "selected", "canEdit", "locale"],
  methods: {
    updateTitle() {
      this.$axios.put(`/portfolio/images/${this.image.id}`, {
        image: { [`title_${this.locale}`]: this.image[`title_${this.locale}`] },
      })
    },
    immovisionEmit(method) {
      this.$emit("immovision-handler", { method: method, image: this.image})
    }
  },
}
</script>
<style scoped>
.color-effect {
  background: -webkit-linear-gradient(#934edc, #514cef);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.dropdown-hover:hover .hover-visibility {
  visibility: visible;
}
.hover-visibility {
  visibility: hidden;
}

.rainbow-box {
  border-right: 2px solid transparent;
  border-bottom: 2px solid transparent;
  border-image: linear-gradient(to top right, #b827fc 0%, #2c90fc 25%, #b8fd33 50%, #fec837 75%, #fd1892 100%);
  border-image-slice: 1;
}
</style>

<style lang="scss" scoped>
.property-image {
  width: 140px;
  height: 140px;
  margin: 1rem 1rem 0 0;
  outline: 1px solid #dedede;
  border-radius: 3px;
  position: relative;

  &.is-selected {
    outline: 3px solid rgb(169, 206, 255);
  }
}

.property-image .image-bg {
  width: 100%;
  height: calc(100% - 36px);
  background-size: cover;
  background-position: center;
  position: relative;
}

.property-image .action-trigger {
  position: absolute;
  top: 10px;
  width: 30px;
  height: 30px;
  background: #000;
  padding-top: 6px;
  border-radius: 2px;
  opacity: 0.7;
  color: white;
  text-align: center;
  display: none;
}

.property-image .delete-trigger {
  right: 10px;
}

.property-image .edit-trigger {
  left: 10px;
}

.property-image .fullscreen-trigger {
  top: initial;
  right: 10px;
  bottom: 46px;
}

.property-image .watermark-trigger {
  left: 55px;
}

.property-image .image-indicators {
  position: absolute;
  left: 10px;
  bottom: 46px;
  line-height: 30px;
  color: white;
}

.property-image .image-floorplan-indicator {
  background: rgba(0, 0, 0, 0.7);
  margin-right: 10px;
  padding: 0 6px;
  display: none;
}

.property-image .image-private-indicator {
  background: rgba(0, 0, 0, 0.7);
  padding: 0 6px;
  display: none;
}

.property-image:hover .action-trigger {
  display: block;
}

.property-image .image-title {
  width: 100%;
  height: 36px;
  padding: 8px;
  border: none;
  overflow: hidden;
  white-space: nowrap;
}

.property-image.is-title-image::before {
  content: "Titelbild";
  position: absolute;
  left: 0;
  top: 0;
  transform: translateY(-100%);
  background-color: #0f55eb;
  color: white;
  font-weight: 400;
  font-size: 10px;
  padding: 2px 12px;
  z-index: 1;
}

.property-image.is-floorplan .image-floorplan-indicator,
.property-image.is-private .image-private-indicator {
  display: inline-block;
}
</style>
