<template>
  <Print :open="open" :styles="styles" :title="policy.title" v-on:close="close">
    <div class="book">
      <div class="page">
        <div class="subpage">
          <small class="text-muted">
            Seite 1 von 2 - Nachweis {{ policy.messageId }} - Formular-Link
            {{ $customFilters.datetime(policy.createdAt) }}: {{ policy.receiverEmail }} - Nachweis-{{
              policy.messageId
            }}.text
          </small>

          <main class="">
            <h3 class="mt-5 mb-5">{{ policy.name }}</h3>

            <p v-if="policy.message && policy.forExposee">
              Angebots-E-Mail: {{ policy.message.subject }} ({{ $customFilters.datetime(policy.createdAt) }})
            </p>

            <p class="mt-5 mb-5" v-if="policy.message && policy.message.broker">
              Hinweis: Nach der erfolgreichen Betätigung des Formulars wurde dieser Nachweis am
              {{ $customFilters.datetime(policy.createdAt) }} für {{ policy.message.broker.name }} ({{
                policy.message.broker.senderEmail
              }}), {{ policy.message.broker.companyName }} gespeichert.
            </p>
            <table class="table table-bordered">
              <tr v-if="policy.category == 'buyer_order'">
                <td style="width: 175px">Exposé-ID</td>
                <td>
                  <strong>{{ policy.messageId }}</strong>
                </td>
              </tr>
              <tr v-if="policy.properties">
                <td style="width: 175px">Objekt</td>
                <td>
                  <strong>{{ policy.properties.map(n => n.name).join(", ") }}</strong>
                </td>
              </tr>
              <tr v-if="policy.client">
                <td style="width: 175px">Versand an</td>
                <td>
                  <strong>{{ policy.client.name }}</strong>
                </td>
              </tr>
              <tr>
                <td style="width: 175px">E-Mail-Adresse</td>
                <td>
                  <strong>{{ policy.receiverEmail }}</strong>
                </td>
              </tr>
            </table>

            <table class="table table-bordered">
              <tr>
                <td style="width: 175px">Aufruf am</td>
                <td>
                  <strong>{{ $customFilters.datetime(policy.createdAt) }}</strong>
                </td>
              </tr>
              <tr>
                <td style="width: 175px">IP-Adresse des Kunden</td>
                <td>
                  <strong>{{ policy.ip }}</strong>
                </td>
              </tr>
              <tr>
                <td style="width: 175px">Browser des Kunden</td>
                <td>
                  <strong>{{ policy.browserName }}</strong>
                </td>
              </tr>
            </table>
          </main>
        </div>
      </div>

      <div class="page">
        <div class="subpage">
          <small class="text-muted">
            Seite 2 von 2 Nachweis {{ policy.messageId }}- Formular-Link
            {{ $customFilters.datetime(policy.message && policy.message.createdAt) }}: {{ policy.receiverEmail }} -
            Nachweis-{{ policy.messageId }}.text
          </small>
          <br />
          <br />
          <main>
            <table class="table table-bordered mt-3">
              <tbody>
                <tr>
                  <td colspan="2">Der Kontakt {{ policy.client && policy.client.name }} hat folgendes bestätigt:</td>
                </tr>
                <template v-if="policy.brokerOrderTextSnapshot">
                  <article v-html="policy.brokerOrderTextSnapshot" class="p-2"></article>
                </template>
                <template v-else-if="policy.selectedCheckboxLabels">
                  <tr v-for="(checkbox, idx) in policy.selectedCheckboxLabels" :key="idx">
                    <td><strong v-html="checkbox"></strong></td>
                    <td style="width: 50px" class="text-center">
                      <fa-icon name="check" class="text-success" />
                    </td>
                  </tr>
                </template>
                <template v-else>
                  <tr v-if="!policy.onlyContactAcceptance">
                    <td><strong>Widerrufsbelehrung gelesen und verstanden</strong></td>
                    <td style="width: 50px" class="text-center">
                      <fa-icon v-if="policy.read" name="check" class="text-success" />
                      <fa-icon v-else name="times" class="text-danger" />
                    </td>
                  </tr>
                  <tr v-if="!policy.onlyContactAcceptance">
                    <td><strong>Vorzeitiger Beginn der Maklertätigkeit akzeptiert</strong></td>
                    <td style="width: 50px" class="text-center">
                      <fa-icon v-if="policy.acceptService" name="check" class="text-success" />
                      <fa-icon v-else name="times" class="text-danger" />
                    </td>
                  </tr>

                  <tr v-if="policy.acceptTerms">
                    <td><strong>AGB gelesen und akzeptiert</strong></td>
                    <td style="width: 50px" class="text-center">
                      <fa-icon name="check" class="text-success" />
                    </td>
                  </tr>

                  <tr v-if="policy.acceptPrivacy">
                    <td><strong>Datenschutzerklärung gelesen und verstanden</strong></td>
                    <td style="width: 50px" class="text-center">
                      <fa-icon name="check" class="text-success" />
                    </td>
                  </tr>

                  <tr v-if="policy.acceptContact">
                    <td>
                      <strong>
                        Hiermit bestätige ich, dass ich zukünftig durch {{ $db.shopData.name }} kontaktiert und per
                        E-Mail über neue Objekte oder Firmenneuigkeiten informiert werden darf.
                      </strong>
                    </td>
                    <td style="width: 50px" class="text-center">
                      <fa-icon v-if="policy.acceptContact" name="check" class="text-success" />
                      <fa-icon v-else name="times" class="text-danger" />
                    </td>
                  </tr>

                  <tr v-if="policy.acceptGdpr">
                    <td><strong>Speicherung zugestimmt</strong></td>
                    <td style="width: 50px" class="text-center">
                      <fa-icon v-if="policy.acceptStorage" name="check" class="text-success" />
                      <fa-icon v-else name="times" class="text-danger" />
                    </td>
                  </tr>
                  <tr v-if="policy.acceptGdpr">
                    <td><strong>Hinweispflichten Art. 13/14 der EU-DSGVO zur Kenntnis genommen</strong></td>
                    <td style="width: 50px" class="text-center">
                      <fa-icon name="check" class="text-success" />
                    </td>
                  </tr>
                </template>
              </tbody>
            </table>
            <div v-if="policy.properties.length && policy.lpUrl && !policy.noteAttachments.length" class="mb-3">
              <p>
                Landing-Page URL:
                <br />
                <a :href="policy.lpUrl" target="_blank" class="underline link">{{ policy.lpUrl }}</a>
              </p>
            </div>
            <div class="mb-3" v-if="policy.projects.length">
              <p class="m-0"><strong>Projekte</strong></p>
              <p v-for="item in policy.projects" :key="item.id" class="mb-2">
                <a :href="item.path" target="_blank" class="underline link">{{ item.name }}</a>
              </p>
            </div>
            <div class="mb-3" v-if="policy.properties.length">
              <p class="m-0"><strong>Objekte</strong></p>
              <p v-for="item in policy.properties" :key="item.id" class="mb-2">
                <a :href="item.path" target="_blank" class="underline link">{{ item.name }}</a>
              </p>
            </div>
            <div class="mb-3" v-if="policy.noteAttachments.length">
              <p class="m-0"><strong>Dateien</strong></p>
              <p v-for="item in policy.noteAttachments" :key="item.id" class="mb-2">
                <a :href="item.url" target="_blank" class="underline link">{{ item.name }}</a>
              </p>
            </div>
          </main>
        </div>
      </div>
    </div>
  </Print>
</template>

<script>
import Print from "@/components/Print"

export default {
  emits: ["update:open"],
  props: {
    policy: { type: Object },
    open: { type: Boolean },
  },
  components: {
    Print,
  },
  methods: {
    close() {
      this.$emit("update:open", false)
    },
  },
  data() {
    return {
      styles: [
        "/print.css",
        "https://stackpath.bootstrapcdn.com/bootstrap/4.1.3/css/bootstrap.min.css",
        "https://pro.fontawesome.com/releases/v5.15.4/css/all.css",
      ],
    }
  },
}
</script>
